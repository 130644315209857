import React from 'react';
import { ISimplePage } from './types';
import style from './style.module.scss';
import { Paper, Typography, Divider, Button } from '@material-ui/core';
import classNames from 'classnames';
import { themeTypes } from 'shared-types/WidgetTypes';
import {renderIf} from "shared-services/react-utils-service";

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'SimplePage';

export default function SimplePage({
  theme, title, body, bodyIsMarkDown, buttons, buttonText, buttonHref, bodyComponentType,
  children, customButtonWrapClass, childContentBelowBody, buttonCallback
}: ISimplePage & {children?: any}) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  return (
    <Paper elevation={1} className={style.root}>

      {renderIf(title, () => (
        <>
          <Typography variant="h2" className={style.title} data-testid="title">
            {title}
          </Typography>

          <Divider className={classNames({
            [style.divider]: true,
            [style.dividerIsDark]: isDark
          })} />
        </>
      ))}

      {!childContentBelowBody && children || null}

      {renderIf(body, () => (
        <Typography
          variant="body1"
          data-testid="body"
          component={bodyComponentType || bodyIsMarkDown ? 'div' : 'p'}
          className={style.body}
        >
          {bodyIsMarkDown
            ? <ReactMarkdown source={body} escapeHtml={false} />
            : body
          }
        </Typography>
      ))}

      {childContentBelowBody && children || null}

      {renderIf(buttons && buttons.length, () => (
        <div className={classNames({
          [style.btnWrap]: true,
          [customButtonWrapClass]: !!customButtonWrapClass
        })} >
          {buttons.map(({buttonText, buttonHref, buttonCallback, color, size}, i) => {
            return buttonHref
              ? <Button key={'link'+i} href={buttonHref} variant="contained" size={size} color={color || 'primary'} data-testid="link" >{buttonText}</Button>
              : <Button key={'link'+i} onClick={buttonCallback} variant="contained" size={size} color={color || 'primary'} data-testid="button" >{buttonText}</Button>
          })}
        </div>
      ))}

      {renderIf(buttonText && (buttonHref || buttonCallback), () => (
        <div className={style.btnWrap}>
          {buttonHref
            ? <Button href={buttonHref} variant="contained" color="primary" data-testid="link" >{buttonText}</Button>
            : <Button onClick={buttonCallback} variant="contained" color="primary" data-testid="button" >{buttonText}</Button>
          }
        </div>
      ))}
    </Paper>
  )
}
