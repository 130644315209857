// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--rBwtH{padding:25px 30px;max-width:600px;margin:20px auto 0}.title--6R4v8{margin-bottom:10px}.divider--ol-oh{margin-bottom:15px;background-color:rgba(0,0,0,.42)}.dividerIsDark--CgQZ5{background-color:rgba(255,255,255,.42)}.btnWrap--44Ppq{margin-top:20px}.btnWrap--44Ppq>*{margin-left:10px}.btnWrap--44Ppq>*:first-child{margin-left:0}.body--sB78Z p{margin:0 0 6px 0;line-height:1.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--rBwtH",
	"title": "title--6R4v8",
	"divider": "divider--ol-oh",
	"dividerIsDark": "dividerIsDark--CgQZ5",
	"btnWrap": "btnWrap--44Ppq",
	"body": "body--sB78Z"
};
export default ___CSS_LOADER_EXPORT___;
